import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { AiAction } from './ai.actions';
import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';
import { ActivityAction } from '../activities';

@Injectable()
export class AiEffects {

  @Effect() analyseDocument$ = this.actions$
    .pipe(ofType(AiAction.ANALYSE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.analyseDocument().form(action.payload.data)
      .mergeMap(response => Observable.from([
        this.aiAction.analyseSuccess()
      ]))
      .catch((error: any) => Observable.of(this.aiAction.analyseFailed()))
    );

  @Effect() findDocuments$ = this.actions$
    .pipe(ofType(AiAction.FINDDOCUMENTS))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.findDocuments().post(action.payload.data)
      .mergeMap(response => Observable.from([
        this.aiAction.findDocumentsSuccess(action.payload.data.id, response as any)
      ]))
      .catch((error: any) => Observable.of(this.aiAction.findDocumentsFailed(action.payload.data.id, error)))
    );

  @Effect() stopFindDocuments$ = this.actions$
  .pipe(ofType(AiAction.STOPFINDDOCUMENTS))
  .filter((action: Action) => !!action.payload)
  .mergeMap((action: Action) => this.api.stopFindDocuments().post(action.payload.data)
    .mergeMap(response => Observable.from([
      this.aiAction.findDocumentsSuccess(action.payload.data.id, response as any)
    ]))
    .catch((error: any) => Observable.of(this.aiAction.findDocumentsFailed(action.payload.data.id, error)))
  );

  @Effect() similarity$ = this.actions$
    .pipe(ofType(AiAction.SIMILARITY))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.similarity().post(action.payload.data)
      .mergeMap(response => Observable.from([
        this.aiAction.similaritySuccess(action.payload.data.id, response)
      ]))
      .catch((error: any) => Observable.of(this.aiAction.similarityFailed(action.payload.data.id, error)))
    );

  @Effect() chatGPT$ = this.actions$
  .pipe(ofType(AiAction.CHATGPT))
  .filter((action: Action) => !!action.payload)
  .mergeMap((action: Action) => this.api.chatGPT().post(action.payload.data)
    .mergeMap(response => Observable.from([
      this.aiAction.chatGPTSuccess(action.payload.id, response)
    ]))
    .catch((error: any) => Observable.of(this.aiAction.chatGPTFailed(action.payload.id, error)))
  );

  @Effect() search$ = this.actions$
    .pipe(ofType(AiAction.SEARCH))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.search().post(action.payload.data)
      .mergeMap(response => Observable.from([
        this.aiAction.searchSuccess(response as any)
      ]))
      .catch((error: any) => Observable.of(this.aiAction.searchFailed(error)))
    );

  constructor(private api: GoAPI,
              private actions$: Actions,
              private aiAction: AiAction) {
  }
}
