import { IAction } from '../shared/common.interfaces';
import { TreeNode } from '../../../core/interface/core.interface';
import { Search, SearchResult } from '../../../ai/interface/ai.interface';
import { AIFindDocumentsPayload } from '../../../ai/components/ai.findDocuments.component';

export class AiAction {
  static key = 'AI';

  static ANALYSE = `[${AiAction.key}] Analyse`;
  static ANALYSE_SUCCESS = `[${AiAction.key}] Analyse success`;
  static ANALYSE_FAILED = `[${AiAction.key}] Analyse failed`;

  static SIMILARITY = `[${AiAction.key}] Similarity`;
  static SIMILARITY_SUCCESS = `[${AiAction.key}] Similarity success`;
  static SIMILARITY_FAILED = `[${AiAction.key}] Similarity failed`;

  static CHATGPT = `[${AiAction.key}] ChatGPT`;
  static CHATGPT_SUCCESS = `[${AiAction.key}] ChatGPT success`;
  static CHATGPT_FAILED = `[${AiAction.key}] ChatGPT failed`;

  static SEARCH = `[${AiAction.key}] Search`;
  static SEARCH_SUCCESS = `[${AiAction.key}] Search success`;
  static SEARCH_FAILED = `[${AiAction.key}] Search failed`;

  static FINDDOCUMENTS = `[${AiAction.key}] Find documents`;
  static FINDDOCUMENTS_SUCCESS = `[${AiAction.key}] Find documents success`;
  static FINDDOCUMENTS_FAILED = `[${AiAction.key}] Find documents failed`;
  static STOPFINDDOCUMENTS = `[${AiAction.key}] Stop Find documents`;

  public analyse(businessAreaId: string, modelId: string, structure: string, ignore: string, engine: string, files: FileList, statusNode: TreeNode): IAction {
    /* Get file */
    const file = files[0];
    /* Build up form data */
    const formData = new FormData();
    formData.append('businessarea', businessAreaId);
    formData.append('model', modelId);
    formData.append('structure', structure);
    formData.append('ignore', ignore);
    formData.append('status', statusNode.id);
    formData.append('engine', engine);
    formData.append('document', file, file.name);
    return {
      type: AiAction.ANALYSE,
      payload: {
        data: formData
      }
    };
  }

  public analyseSuccess() {
    return {
      type: AiAction.ANALYSE_SUCCESS,
      payload: null
    };
  }

  public analyseFailed() {
    return {
      type: AiAction.ANALYSE_FAILED,
      payload: null
    };
  }

  public chatGPTRequest(requestId: string, payload) {
    if (Array.isArray(payload)) {
      return {
        type: AiAction.CHATGPT,
        payload: {
          id: requestId,
          data: {
            conversations: payload
          }
        }
      };
    } else {
      return {
        type: AiAction.CHATGPT,
        payload: {
          id: requestId,
          data: payload
        }
      };
    }
  }
  public chatGPTFailed(requestId: string, error) {
    return {
      type: AiAction.CHATGPT_FAILED,
      payload: {
        response: error
      }
    };
  }
  public chatGPTSuccess(requestId: string, payload: any) {
    return {
      request: requestId,
      type: AiAction.CHATGPT_SUCCESS,
      payload: {
        request: requestId,
        response: payload
      }
    };
  }

  public similarity(requestId: string, input: string[], limit = 0): IAction {
    return {
      type: AiAction.SIMILARITY,
      payload: {
        data: {
          id: requestId,
          data: input,
          limit: limit
        }
      }
    };
  }

  public similaritySuccess(requestId: string, indices: any) {
    return {
      type: AiAction.SIMILARITY_SUCCESS,
      payload: {
        request: requestId,
        response: indices
      }
    };
  }

  public similarityFailed(requestId: string, error) {
    return {
      type: AiAction.SIMILARITY_FAILED,
      payload: {
        request: requestId,
        response: error
      }
    };
  }

  public search(payload: Search): IAction {
    return {
      type: AiAction.SEARCH,
      payload: {
        data: payload
      }
    };
  }

  public searchSuccess(searchResult: SearchResult) {
    return {
      type: AiAction.SEARCH_SUCCESS,
      payload: {
        response: searchResult
      }
    };
  }

  public searchFailed(error) {
    return {
      type: AiAction.SEARCH_FAILED,
      payload: {
        response: error
      }
    };
  }

  public findDocuments(requestId: string, input: AIFindDocumentsPayload): IAction {
    return {
      type: AiAction.FINDDOCUMENTS,
      payload: {
        data: {
          id: requestId,
          data: input,
        }
      }
    };
  }
  public stopFindDocuments(requestId: string, stopId: string): IAction {
    return {
      type: AiAction.STOPFINDDOCUMENTS,
      payload: {
        data: {
          id: stopId,
          data: null,
        }
      }
    };
  }

  public findDocumentsSuccess(requestId: string, responseId: string) {
    return {
      type: AiAction.FINDDOCUMENTS_SUCCESS,
      payload: {
        request: requestId,
        response: responseId
      }
    };
  }

  public findDocumentsFailed(requestId: string, error) {
    return {
      type: AiAction.FINDDOCUMENTS_FAILED,
      payload: {
        request: requestId,
        response: error
      }
    };
  }

}
