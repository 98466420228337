import { Action } from '../shared/common.action';
import { AiAction } from './ai.actions';
import { AiState } from './ai.models';
import { Utils } from '../shared';

export default function reducer(state = new AiState(), action: Action): AiState {

  switch (action.type) {

    case AiAction.ANALYSE: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }

    case AiAction.ANALYSE_SUCCESS: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 0)
      );
    }

    case AiAction.SIMILARITY: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }

    case AiAction.CHATGPT: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }

    case AiAction.CHATGPT_SUCCESS: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AiAction.SIMILARITY_SUCCESS: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AiAction.SIMILARITY_FAILED: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AiAction.SEARCH: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }

    case AiAction.SEARCH_SUCCESS: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AiAction.SEARCH_FAILED: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AiAction.FINDDOCUMENTS: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }
    case AiAction.STOPFINDDOCUMENTS: {
      return <AiState> state.withMutations(s => s
        .updateIn(['isBusy'], () => 1)
      );
    }

    case AiAction.FINDDOCUMENTS_SUCCESS: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case AiAction.FINDDOCUMENTS_FAILED: {
      return <AiState>Utils.updateDiff(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default:
      return state;
  }
}
