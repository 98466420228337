import { Businessarea } from './../../businessareas/businessareas.models';
import { Inject, Injectable } from '@angular/core';
import { ValueMinerAPIUrl, ValueMinerExportAPIUrl, ValueMinerMessagingAPIUrl, ValueMinerOAuthUrl, ValueMinerGoUrl } from '../tokens';
import { TokenService } from './token.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { AppGlobal } from '../../../../app.global';

@Injectable()
export class BackendService {

  protected apiUrl: string;
  protected http: HttpClient;
  protected token: TokenService;

  public static pathJoin(parts: string[]) {
    return parts.join('/').replace(/([^:]\/)\/+/g, '$1');
  }

  constructor(@Inject(ValueMinerAPIUrl) apiUrl: string,
              @Inject(ValueMinerMessagingAPIUrl) messagingApiUrl: string,
              @Inject(ValueMinerExportAPIUrl) exportApiUrl: string,
              @Inject(ValueMinerOAuthUrl) oauthApiUrl: string,
              @Inject(ValueMinerGoUrl) goUrl: string,
              http: HttpClient,
              token: TokenService) {
    this.apiUrl = apiUrl;
    this.http = http;
    this.token = token;
  }

  public get(path: string, token?: string): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('GET', url, {}, true, token);
  }

  public post(path: string, body: any, stringify = true, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json'): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('POST', url, body, stringify, undefined, responseType);
  }

  public put(path: string, body: any, token ?: string): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('PUT', url, body, true, token);
  }

  public remove(path: any, body?: any): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('DELETE', url, body);
  }

  protected request(method: string, url: string, body: {} = {}, stringify = true, token?: string, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json'): Observable<{}> {
    return this.sendRequest((!!token ? null : this.token.get()), method, url, body, stringify, token, responseType)
      .catch((error: any) => {
        if (error.status === 401) {
          return this.sendRequest(this.token.refresh(), method, url, body, stringify, undefined, responseType);
        }
        return Observable.throw(error);
      }).catch((err: any) => {
        if (typeof err.json === 'function') {
          err = err.json();
        }
        return Observable.throw(err);
      });
  }

  protected sendRequest(tokenObs: Observable<string>, method: string, url: string, body: {} = {}, stringify = true, token?: string, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json'): Observable<Object> {
    if (!isNullOrUndefined(token)) {
      return new BehaviorSubject(token).flatMap((accessToken: string) => {
        return this.doRequest(method, url, true, accessToken, stringify, body, responseType);
      });
    }
    return tokenObs.flatMap((accessToken: string) => {
      return this.doRequest(method, url, false, accessToken, stringify, body, responseType);
    });
  }

  protected doRequest(method: string, url: string, useToken: boolean, accessToken: string, stringify: boolean, body: any, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json') {
     /* Headers */
    const headers = { 'Accept': 'application/vnd.api.v2+json', 'Instance': '' + AppGlobal.instanceId, 'BA': '' + AppGlobal.businessAreaId  };
    /* Authorization */
    headers['Authorization'] = (useToken ? 'Token ' : 'Bearer ') + accessToken;
    /* Content type */
    if (stringify) {
      headers['Content-Type'] = 'application/json';
    }
    /* Body */
    body = stringify ? JSON.stringify(body) : body;
    /* Send request */
    return this.http.request(method, url, { headers, body, responseType });
  }
}
