import { Record } from 'immutable';
import { NodeStructureRelationships } from '../nodestructures';
import { NodeDataRelationships } from '../nodedata/nodedata.models';

export const NODES_TYPE_DEFAULT = 0;
export const NODES_TYPE_INITIATIVE = 1;
export const NODES_TYPE_QUESTION = 2;
export const NODES_TYPE_PROJECT = 3;
export const NODES_TYPE_FEASIBILITY_TECH = 4;
export const NODES_TYPE_FEASIBILITY_IP = 5;
export const NODES_TYPE_REQUIREMENT = 6;
export const NODES_TYPE_SOLUTION = 7;
export const NODES_TYPE_LIBRARY = 10;
export const NODES_TYPE_DATASOURCE = 11;
export const NODES_TYPE_TREND = 12;
export const NODES_TYPE_IPCCLASS = 13;
export const NODES_TYPE_PATENT = 14;
export const NODES_TYPE_CLIENT = 15;
export const NODES_TYPE_COMPETITOR = 16;
export const NODES_TYPE_MARKET = 17;
export const NODES_TYPE_DEFINITION = 18;
export const NODES_TYPE_OPERATION = 19;
export const NODES_TYPE_DATATARGET = 20;
export const NODES_TYPE_TECHFIELD = 21;
export const NODES_TYPE_TECHAREA = 22;
export const NODES_TYPE_CCM = 23;
export const NODES_TYPE_SCM = 24;
export const NODES_TYPE_UCIM = 25;
export const NODES_TYPE_CWM = 26;
export const NODES_TYPE_MCM = 27;
export const NODES_TYPE_IPNAVIGATOR = 28;
export const NODES_TYPE_IF = 29;
export const NODES_TYPE_THEN = 30;
export const NODES_TYPE_ELSE = 31;
export const NODES_TYPE_INSTANCE = 32;
export const NODES_TYPE_BUSINESSAREA = 33;
export const NODES_TYPE_MODEL = 34;
export const NODES_TYPE_WORKFLOW = 35;
export const NODES_TYPE_FORM = 36;
export const NODES_TYPE_FORM_TAB = 37;
export const NODES_TYPE_FORM_OPTION = 38;
export const NODES_TYPE_WORKFLOW_QUESTIONNAIRE = 39;
export const NODES_TYPE_WORKFLOW_QUESTIONNAIRE_TAB = 40;
export const NODES_TYPE_WORKFLOW_LINK = 41;
export const NODES_TYPE_NODE = 42;
export const NODES_TYPE_CATEGORY = 43;
export const NODES_TYPE_CATEGORYGROUP = 44;
export const NODES_TYPE_INDUSTRY = 45;
export const NODES_TYPE_MACROTREND = 46;
export const NODES_TYPE_MEGATREND = 47;
export const NODES_TYPE_MICROTREND = 48;
export const NODES_TYPE_IDEA = 49;
export const NODES_TYPE_PRODUCT = 50;
export const NODES_TYPE_MILESTONE = 51;
export const NODES_TYPE_MODULECONFIGURATION = 52;
export const NODES_TYPE_WIDGET = 53;
export const NODES_TYPE_SMARTACTION = 54;
export const NODES_TYPE_DATASHEET = 55;
export const NODES_TYPE_DELETE = 56;
export const NODES_TYPE_GANTT = 57;
export const NODES_TYPE_TABLEGROUP = 58;
export const NODES_TYPE_ALLOWED = 59;
export const NODES_TYPE_ACTIONBAR = 60;
export const NODES_TYPE_MUSTHAVE = 61;
export const NODES_TYPE_ADD = 62;
export const NODES_TYPE_CONNECT = 63;
export const NODES_TYPE_DISCONNECT = 64;
export const NODES_TYPE_SPLASHSCREEN = 65;
export const NODES_TYPE_WELCOME_POWER = 66;
export const NODES_TYPE_WELCOME_REGULAR = 67;
export const NODES_TYPE_WIDGET_ROW = 68;
export const NODES_TYPE_FIELDS = 69;
export const NODES_TYPE_FIELD = 70;
export const NODES_TYPE_METHODOLOGY = 71;
export const NODES_TYPE_ACTION = 72;
export const NODES_TYPE_SETUPACTION = 73;
export const NODES_TYPE_OBJECTIVE = 74;
export const NODES_TYPE_CHALLENGE = 75;
export const NODES_TYPE_DELIVERABLE = 76;
export const NODES_TYPE_STRATEGYMAP = 77;
export const NODES_TYPE_PROJECTPORTFOLIO = 78;
export const NODES_TYPE_ACTIONGROUP = 79;
export const NODES_TYPE_HOMEACTION = 80;
export const NODES_TYPE_NODETYPEGROUP = 81;
export const NODES_TYPE_NEXT = 82;
export const NODES_TYPE_SORTING = 83;
export const NODES_TYPE_FACTSHEET = 84;
export const NODES_TYPE_HUMANRESOURCE = 85;
export const NODES_TYPE_GROUP = 86;
export const NODES_TYPE_CAPABILITY = 87;
export const NODES_TYPE_FILTERS = 88;
export const NODES_TYPE_ORGANISATIONALUNIT = 89;
export const NODES_TYPE_TEAM = 90;
export const NODES_TYPE_AI = 91;
export const NODES_TYPE_TEXTBLOCK = 92;
export const NODES_TYPE_ANALYSE_STRUCTURE = 93;
export const NODES_TYPE_ENTITY = 94;
export const NODES_TYPE_ANALYSE = 95;
export const NODES_TYPE_ANALYSEFOLDER = 96;
export const NODES_TYPE_KEYWORD = 97;
export const NODES_TYPE_COMMENT = 98;
export const NODES_TYPE_REGION = 99;
export const NODES_TYPE_RELATIONSHIP = 100;
export const NODES_TYPE_EDIT = 101;
export const NODES_TYPE_PARENT = 102;
export const NODES_TYPE_CHILD = 103;
export const NODES_TYPE_GLOBALFILTER = 104;
export const NODES_TYPE_PLANTS = 105;
export const NODES_TYPE_FUNCTIONS = 106;
export const NODES_TYPE_COLUMN = 107;
export const NODES_TYPE_TASK = 108;
export const NODES_TYPE_CAPACITY = 109;
export const NODES_TYPE_DRAG = 110;
export const NODES_TYPE_DROP = 111;
export const NODES_TYPE_COLORLABELPROVIDER = 112;
export const NODES_TYPE_COUNTERMEASURE = 113;
export const NODES_TYPE_SUM = 114;
export const NODES_TYPE_GLOBALSUM = 115;
export const NODES_TYPE_IGNORE = 116;
export const NODES_TYPE_UPDATE = 117;
export const NODES_TYPE_INFRACTION = 118;
export const NODES_TYPE_GAP = 119;
export const NODES_TYPE_SETTINGS = 120;
export const NODES_TYPE_SEARCH = 121;
export const NODES_TYPE_SELECT = 122;
export const NODES_TYPE_TOGGLE = 123;
export const NODES_TYPE_WIDGET_HEADER = 124;
export const NODES_TYPE_WIDGET_FOOTER = 125;
export const NODES_TYPE_PLACE_CHILDREN_ASIDE = 126;
export const NODES_TYPE_CURVED_LINK = 127;
export const NODES_TYPE_LEGEND = 128;
export const NODES_TYPE_FOOTER_TABS = 129;
export const NODES_TYPE_FOCUS = 130;
export const NODES_TYPE_STEP = 131;
export const NODES_TYPE_ADD_ELEMENT = 132;
export const NODES_TYPE_ADD_CHILD = 133;
export const NODES_TYPE_LINK = 134;
export const NODES_TYPE_RECEIVER = 135;
export const NODES_TYPE_STRUCTURE = 136;
export const NODES_TYPE_ORGLEVEL_1 = 137;
export const NODES_TYPE_ORGLEVEL_2 = 138;
export const NODES_TYPE_ORGLEVEL_3 = 139;
export const NODES_TYPE_WBS_PROGRAM = 140;
export const NODES_TYPE_WBS_WORKPACKAGE = 141;
export const NODES_TYPE_WBS_ACTIVITY = 142;
export const NODES_TYPE_WBS_SUBPROJECTS = 143;
export const NODES_TYPE_SKILLS = 144;
export const NODES_TYPE_DEMAND = 145;
export const NODES_TYPE_ASSET_MACHINE = 146;
export const NODES_TYPE_ASSET_MODULE = 147;
export const NODES_TYPE_PRODUCTGROUP = 148;
export const NODES_TYPE_ASSET_GROUP = 149;
export const NODES_TYPE_BADWORD = 150;
export const NODES_TYPE_MEMBER = 151;
export const NODES_TYPE_DUPLICATE = 152;
export const NODES_TYPE_REVERSE = 153;
export const NODES_TYPE_ROUNDED = 154;
export const NODES_TYPE_SUGGESTIONS = 155;
export const NODES_TYPE_FULL = 156;
export const NODES_TYPE_CENTER = 157;
export const NODES_TYPE_HIERARCHY = 158;
export const NODES_TYPE_REMAINING = 159;
export const NODES_TYPE_ONLY_ONE_STACK = 160;
export const NODES_TYPE_VERTICAL = 161;
export const NODES_TYPE_HORIZONTAL = 162;
export const NODES_TYPE_AXIS = 163;
export const NODES_TYPE_TABLEGROUPMONTHS = 164;
export const NODES_TYPE_DEFAULTVALUES = 165;
export const NODES_TYPE_STRAIGHT_LINKS = 166;
export const NODES_TYPE_DIRECT_CHAIN = 167;
export const NODES_TYPE_FORM_ROW = 168;
export const NODES_TYPE_FORM_COLUMN = 169;
export const NODES_TYPE_REMINDER = 170;
export const NODES_TYPE_ASSET_POSITION = 171;
export const NODES_TYPE_MANUFACTURING_SLOT = 172;
export const NODES_TYPE_BY = 173;
export const NODES_TYPE_VALUE = 174;
export const NODES_TYPE_PAGINATION = 175;
export const NODES_TYPE_SLOT = 176;
export const NODES_TYPE_LINE = 177;
export const NODES_TYPE_MACHINE = 178;
export const NODES_TYPE_MODULE = 179;
export const NODES_TYPE_TRANSFER = 180;
export const NODES_TYPE_MUTATION = 181;
export const NODES_TYPE_STANDARD_LINE = 182;
export const NODES_TYPE_ORGLEVEL_6 = 183;
export const NODES_TYPE_ORGLEVEL_7 = 184;
export const NODES_TYPE_ORGLEVEL_5 = 185;
export const NODES_TYPE_MY = 186;
export const NODES_TYPE_ROLLOUT = 187;
export const NODES_TYPE_PROCESS = 188;
export const NODES_TYPE_PROJECT_CATEGORY = 189;
export const NODES_TYPE_PREFILTER = 190;
export const NODES_TYPE_VERSION = 191;
export const NODES_TYPE_POSITION = 192;
export const NODES_TYPE_STANDARD_CONFIGURATION = 193;
export const NODES_TYPE_SUPPLIER = 194;
export const NODES_TYPE_OFFLINE = 195;
export const NODES_TYPE_EVENT = 196;
export const NODES_TYPE_MACHINE_TYPE = 197;
export const NODES_TYPE_LC_STATE = 198;
export const NODES_TYPE_NODETYPEGROUPS = 199;
export const NODES_TYPE_ALTERNATIVE = 200;
export const NODES_TYPE_EVENT_CATEGORY = 201;
export const NODES_TYPE_LABEL = 202;
export const NODES_TYPE_SHARE = 203;
export const NODES_TYPE_TREE = 204;
export const NODES_TYPE_NOT = 205;
export const NODES_TYPE_NOTIFICATIONS = 206;
export const NODES_TYPE_PRIVATE = 207;
export const NODES_TYPE_TRAVERSER = 208;
export const NODES_TYPE_NFM = 209;
export const NODES_TYPE_PRODUCT_AREA = 210;
export const NODES_TYPE_SEQUENCE = 211;
export const NODES_TYPE_ENTRY = 212;
export const NODES_TYPE_ANSWER = 213;
export const NODES_TYPE_TAG = 214;
export const NODES_TYPE_DOCUMENT = 215;
export const NODES_TYPE_DATASTORAGE = 216;
export const NODES_TYPE_VIDEO = 217;
export const NODES_TYPE_PROMPT = 218;
export const NODES_TYPE_EXTERNAL_INPUT = 219;
export const NODES_TYPE_INPUT = 220;
export const NODES_TYPE_COEFFICIENT = 221;
export const NODES_TYPE_CALCULATION = 222;
export const NODES_TYPE_CONVERSION = 223;
export const NODES_TYPE_PERCENTAGE = 224;
export const NODES_TYPE_CONDITION = 225;
export const NODES_TYPE_DECIMALS = 226;
export const NODES_TYPE_THOUSANDS = 227;
export const NODES_TYPE_DATE = 228;
export const NODES_TYPE_EXPORT = 229;
export const NODES_TYPE_COLOR = 230;
export const NODES_TYPE_STYLE = 231;

const nodeStateRecord = Record({
  isBusy: 0
});

export class NodeState extends nodeStateRecord {
  isBusy: number;
}

const nodeRecord = Record({
  /* Structure */
  id: undefined,
  type: 'nodes',
  tempId: undefined,
  level: 0,
  active: true,
  positionX: 0,
  autoPositionX: 0,
  autoPositionUncrossed: 0,
  updatedAt: undefined,
  isSidestep: undefined,
  isInput: false,
  modelId: undefined,

  /* Data */
  name: '',
  reference: '',
  crossReference: '',
  createdAt: undefined,
  description: '',
  description1: '',
  description2: '',
  description3: '',
  description4: '',
  documentUri: '',
  upload_uri: '',
  status: 0,
  responsibleId: null,
  groupId: undefined,
  targetDate: null,
  actualDate: null,
  aggregationKPI: 0,
  KPI: 0,
  businessBenefit: 0,
  commercialStatus: 0,
  complexity: 0,
  uncertainty: 0,
  risk: 0,
  sizeType: 0,
  budget: 0,
  budgetActual: 0,
  budgetRemaining: 0,
  budgetCalculated: 0,
  benefitBudget: 0,
  benefitActual: 0,
  benefitRemaining: 0,
  costBudget: 0,
  costActual: 0,
  costRemaining: 0,
  investBudget: 0,
  investActual: 0,
  investRemaining: 0,
  businesscalculation: null,
  startDate: null,
  actualStartDate: undefined,
  percentageComplete: undefined,
  priority: undefined,
  structure_duplicate_original_id: undefined,
  data_duplicate_original_id: undefined,
  storypoints: 0,
  costtype: 0,
  nodeType: NODES_TYPE_DEFAULT,
  onreport: true,
  sync: undefined,

  creationDate: undefined,
  lastUpdated: undefined,
  currency: '',

  riskProfileCategory: undefined,
  riskProfileAssessment: undefined,
  riskProfileCountermeasures: '',
  time: 0,
  timeDescription: '',
  cost: 0,
  costDescription: '',
  quality: 0,
  qualityDescription: '',
  executive: 0,
  executiveDescription: '',
  program: 0,
  programDescription: '',
  functional: 0,
  functionalDescription: '',
  resource: 0,
  resourceDescription: '',
  organizational: 0,
  organizationalDescription: '',
  technical: 0,
  technicalDescription: '',
  linkToParent: undefined,

  alsoPublishedAs: '',
  cpc: '',
  cpc4: '',
  ipc: '',
  ipc4: '',
  applicants: '',
  techAreas: '',
  techFields: '',
  authorities: '',
  sorting: 0,

  color: '',
  widget: '',
  icon: '',
  obligatory: false,

  workFlowModel: 0,
  workFlowOperation: '',
  workFlowIfClause: '',
  workFlowId: '',
  workFlowFormId: '',
  workFlowFormParams: '',
  workFlowLink: '',
  workFlowTabId: '',

  formId: '',
  formFieldId: '',
  formFieldType: '',
  formFieldControlType: '',
  formFieldDropdownValue: '',
  formFieldEditable: false,
  formFieldSortable: true,
  formFieldFilterable: false,
  formFieldFixed: false,
  formFieldSearchable: false,
  formFieldWidth: null,
  formFieldBucketId: '',
  formFieldCalculation: '',
  formFieldShape: '',
  formBucket: false,
  form_default_value: '',

  dashboardCols: null,
  dashboardRows: null,
  dashboardX: null,
  dashboardY: null,

  fieldConversion: null,
  fieldCreateType: null,
  fieldSkipIfExists: false,
  colorLabelProvider: null,

  fullscreen: false,
  printable: false,
  hideWidget: false,

  ai_type: '',
  ai_preset: '',

  numberField1: 0,
  numberField2: 0,
  numberField3: 0,
  numberField4: 0,
  numberField5: 0,
  numberField6: 0,
  numberField7: 0,
  numberField8: 0,
  numberField9: 0,
  numberField10: 0,
  textField1: '',
  textField2: '',
  textField3: '',
  textField4: '',
  textField5: '',
  textField6: '',
  textField7: '',
  textField8: '',
  textField9: '',
  textField10: 0,
  textField11: '',
  textField12: '',
  textField13: '',
  textField14: '',
  textField15: '',
  textField16: '',
  textField17: '',
  textField18: '',
  textField19: '',
  textField20: '',
  textField21: '',
  textField22: '',
  textField23: '',
  textField24: '',
  textField25: '',
  textField26: '',
  textField27: '',
  textField28: '',
  textField29: '',
  textField30: '',
  textField31: '',
  textField32: '',
  textField33: '',
  textField34: '',
  textField35: '',
  textField36: '',
  textField37: '',
  textField38: '',
  textField39: '',
  textField40: '',
  formFieldValidation: '',
  formFieldValidationMessage: '',

  bestCase: 0,

  relationships: undefined
});

export class Node extends nodeRecord {
  /* Structure */
  id: string;
  type: string;
  tempId: string;
  level: number;
  active: boolean;
  positionX: number;
  autoPositionX: number;
  autoPositionUncrossed: number;
  updatedAt: number;
  isSidestep: boolean;
  isInput: boolean;
  modelId: string;
  related: number[];

  /* Data */
  name: string;
  reference: string;
  crossReference: string;
  createdAt: number;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  documentUri: string;
  upload_uri: string;
  status: number;
  responsibleId: number;
  groupId: number;
  targetDate: string;
  actualDate: string;
  aggregationKPI: number;
  KPI: number;
  businessBenefit: number;
  commercialStatus: number;
  complexity: number;
  uncertainty: number;
  risk: number;
  sizeType: number;
  budget: number;
  budgetActual: number;
  budgetRemaining: number;
  budgetCalculated: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  businesscalculation: string;

  creationDate: number;
  lastUpdated: number;
  currency: string;

  startDate?: string;
  actualStartDate?: string;
  percentageComplete?: number;
  priority?: number;
  sorting?: number;

  riskProfileCategory?: number;
  riskProfileAssessment?: number;
  riskProfileCountermeasures?: string;
  time?: number;
  timeDescription?: string;
  cost?: number;
  costDescription?: string;
  quality?: number;
  qualityDescription?: string;
  executive?: number;
  executiveDescription?: string;
  program?: number;
  programDescription?: string;
  functional?: number;
  functionalDescription?: string;
  resource?: number;
  resourceDescription?: string;
  organizational?: number;
  organizationalDescription?: string;
  technical?: number;
  technicalDescription?: string;
  storypoints: number;
  costtype: number;

  onreport?: boolean;
  nodeType?: number;
  linkToParent?: string;

  alsoPublishedAs?: string;
  cpc?: string;
  cpc4?: string;
  ipc?: string;
  ipc4?: string;
  applicants?: string;
  techAreas?: string;
  techFields?: string;
  authorities?: string;
  sync?: any;

  color?: string;
  widget?: string;
  icon?: string;
  obligatory?: boolean;

  workFlowModel?: number;
  workFlowOperation?: string;
  workFlowIfClause?: string;
  workFlowId?: string;
  workFlowFormId?: string;
  workFlowFormParams?: string;
  workFlowLink?: string;
  workFlowTabId?: string;

  formId: string;
  formFieldId: string;
  formFieldType: string;
  formFieldControlType: string;
  formFieldDropdownValue: string;
  formFieldEditable: boolean;
  formFieldSortable: boolean;
  formFieldFilterable: boolean;
  formFieldFixed: boolean;
  formFieldSearchable: boolean;
  formFieldWidth: string;
  formFieldShape: string;
  formFieldBucketId: string;
  formBucket: boolean;
  formFieldCalculation: string;
  form_default_value: string | number;

  dashboardCols: number;
  dashboardRows: number;
  dashboardX: number;
  dashboardY: number;

  fieldConversion: string;
  fieldCreateType: string;
  fieldSkipIfExists: boolean;
  colorLabelProvider: string;

  fullscreen: boolean;
  printable: boolean;
  hideWidget: boolean;

  ai_type: string;
  ai_preset: string;

  structure_duplicate_original_id: number;
  data_duplicate_original_id: number;

  numberField1: number;
  numberField2: number;
  numberField3: number;
  numberField4: number;
  numberField5: number;
  numberField6: number;
  numberField7: number;
  numberField8: number;
  numberField9: number;
  numberField10: number;
  textField1: string;
  textField2: string;
  textField3: string;
  textField4: string;
  textField5: string;
  textField6: string;
  textField7: string;
  textField8: string;
  textField9: string;
  textField10: number;
  textField11: string;
  textField12: string;
  textField13: string;
  textField14: string;
  textField15: string;
  textField16: string;
  textField17: string;
  textField18: string;
  textField19: string;
  textField20: string;
  textField21: string;
  textField22: string;
  textField23: string;
  textField24: string;
  textField25: string;
  textField26: string;
  textField27: string;
  textField28: string;
  textField29: string;
  textField30: string;
  textField31: string;
  textField32: string;
  textField33: string;
  textField34: string;
  textField35: string;
  textField36: string;
  textField37: string;
  textField38: string;
  textField39: string;
  textField40: string;
  formFieldValidation: string;
  formFieldValidationMessage: string;

  bestCase: number;

  relationships: NodeStructureRelationships & NodeDataRelationships;
}

const nodeCreateRecord = Record({
  /* Structure */
  id: undefined,
  type: 'nodes',
  tempId: undefined,
  level: 0,
  active: true,
  positionX: 0,
  autoPositionX: 0,
  autoPositionUncrossed: 0,
  sorting: 0,
  updatedAt: undefined,
  isSidestep: undefined,
  modelId: undefined,
  version_id: undefined,

  /* Data */
  dataId: null,
  name: '',
  reference: '',
  crossReference: '',
  createdAt: undefined,
  description: '',
  description1: '',
  description2: '',
  description3: '',
  description4: '',
  documentUri: '',
  upload_uri: '',
  status: 0,
  responsibleId: null,
  groupId: undefined,
  targetDate: null,
  actualDate: null,
  aggregationKPI: 0,
  KPI: 0,
  businessBenefit: 0,
  commercialStatus: 0,
  complexity: 0,
  uncertainty: 0,
  risk: 0,
  sizeType: 0,
  budget: 0,
  budgetActual: 0,
  budgetRemaining: 0,
  budgetCalculated: 0,
  benefitBudget: 0,
  benefitActual: 0,
  benefitRemaining: 0,
  costBudget: 0,
  costActual: 0,
  costRemaining: 0,
  investBudget: 0,
  investActual: 0,
  investRemaining: 0,
  businesscalculation: null,
  startDate: null,
  actualStartDate: undefined,
  percentageComplete: undefined,
  priority: undefined,
  structure_duplicate_original_id: undefined,
  data_duplicate_original_id: undefined,
  storypoints: 0,
  costtype: 0,
  nodeType: NODES_TYPE_DEFAULT,
  onreport: true,

  creationDate: undefined,
  lastUpdated: undefined,
  currency: '',

  riskProfileCategory: undefined,
  riskProfileAssessment: undefined,
  riskProfileCountermeasures: '',
  time: 0,
  timeDescription: '',
  cost: 0,
  costDescription: '',
  quality: 0,
  qualityDescription: '',
  executive: 0,
  executiveDescription: '',
  program: 0,
  programDescription: '',
  functional: 0,
  functionalDescription: '',
  resource: 0,
  resourceDescription: '',
  organizational: 0,
  organizationalDescription: '',
  technical: 0,
  technicalDescription: '',
  linkToParent: undefined,

  alsoPublishedAs: '',
  cpc: '',
  cpc4: '',
  ipc: '',
  ipc4: '',
  applicants: '',
  techAreas: '',
  techFields: '',
  authorities: '',

  color: '',
  widget: '',
  icon: '',
  obligatory: false,

  workFlowModel: 0,
  workFlowOperation: '',
  workFlowIfClause: '',
  workFlowId: '',
  workFlowFormId: '',
  workFlowFormParams: '',
  workFlowLink: '',
  workFlowTabId: '',

  formId: '',
  formFieldId: '',
  formFieldType: '',
  formFieldControlType: '',
  formFieldDropdownValue: '',
  formFieldEditable: false,
  formFieldSortable: true,
  formFieldFilterable: false,
  formFieldFixed: false,
  formFieldSearchable: false,
  formFieldWidth: null,
  formFieldShape: '',
  formFieldBucketId: '',
  formFieldCalculation: '',
  formBucket: false,
  form_default_value: '',

  dashboardCols: null,
  dashboardRows: null,
  dashboardX: null,
  dashboardY: null,

  fieldConversion: null,
  fieldCreateType: null,
  fieldSkipIfExists: false,
  colorLabelProvider: null,

  fullscreen: false,
  printable: false,
  hideWidget: false,

  numberField1: 0,
  numberField2: 0,
  numberField3: 0,
  numberField4: 0,
  numberField5: 0,
  numberField6: 0,
  numberField7: 0,
  numberField8: 0,
  numberField9: 0,
  numberField10: 0,
  textField1: '',
  textField2: '',
  textField3: '',
  textField4: '',
  textField5: '',
  textField6: '',
  textField7: '',
  textField8: '',
  textField9: '',
  textField10: 0,
  textField11: '',
  textField12: '',
  textField13: '',
  textField14: '',
  textField15: '',
  textField16: '',
  textField17: '',
  textField18: '',
  textField19: '',
  textField20: '',
  textField21: '',
  textField22: '',
  textField23: '',
  textField24: '',
  textField25: '',
  textField26: '',
  textField27: '',
  textField28: '',
  textField29: '',
  textField30: '',
  textField31: '',
  textField32: '',
  textField33: '',
  textField34: '',
  textField35: '',
  textField36: '',
  textField37: '',
  textField38: '',
  textField39: '',
  textField40: '',
  formFieldValidation: '',
  formFieldValidationMessage: '',

  ai_type: '',
  ai_preset: '',

  private: undefined,
  sync: undefined
});

export class NodeCreate extends nodeCreateRecord {
  /* Structure */
  id: string;
  level: number;
  active: boolean;
  positionX: number;
  isSidestep: boolean;
  modelId: string;
  version_id: number;
  /* Data */
  dataId: string;
  name: string;
  crossReference: string;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  documentUri: string;
  upload_uri: string;
  status: number;
  responsibleId: number;
  groupId: number;
  targetDate: string;
  actualDate: string;
  aggregationKPI: number;
  KPI: number;
  businessBenefit: number;
  commercialStatus: number;
  complexity: number;
  uncertainty: number;
  risk: number;
  sizeType: number;
  budget: number;
  budgetActual: number;
  budgetRemaining: number;
  budgetCalculated: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  fieldConversion: string;
  fieldCreateType: string;
  fieldSkipIfExists: boolean;
  colorLabelProvider: string;
  formFieldSearchable: boolean;
  formFieldWidth: string;
  fullscreen: boolean;
  hideWidget: boolean;
  printable: boolean;
  dashboardCols: number;
  dashboardRows: number;
  dashboardX: number;
  dashboardY: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  businesscalculation: string;
  currency: string;
  startDate?: string;
  actualStartDate?: string;
  percentageComplete?: number;
  priority?: number;
  sorting?: number;
  riskProfileCategory?: number;
  riskProfileAssessment?: number;
  riskProfileCountermeasures?: string;
  time?: number;
  timeDescription?: string;
  cost?: number;
  costDescription?: string;
  quality?: number;
  qualityDescription?: string;
  executive?: number;
  executiveDescription?: string;
  program?: number;
  programDescription?: string;
  functional?: number;
  functionalDescription?: string;
  resource?: number;
  resourceDescription?: string;
  organizational?: number;
  organizationalDescription?: string;
  technical?: number;
  technicalDescription?: string;
  storypoints: number;
  costtype: number;
  onreport?: boolean;
  nodeType?: number;
  linkToParent?: string;
  alsoPublishedAs?: string;
  cpc?: string;
  cpc4?: string;
  ipc?: string;
  ipc4?: string;
  applicants?: string;
  techAreas?: string;
  techFields?: string;
  authorities?: string;
  structure_duplicate_original_id: number;
  data_duplicate_original_id: number;
  color?: string;
  widget?: string;
  icon?: string;
  obligatory?: boolean;
  workFlowModel?: number;
  workFlowOperation?: string;
  workFlowIfClause?: string;
  workFlowId?: string;
  workFlowFormId?: string;
  workFlowFormParams?: string;
  workFlowLink?: string;
  workFlowTabId?: string;
  formId?: string;
  formFieldId?: string;
  formFieldType?: string;
  formFieldControlType?: string;
  formFieldDropdownValue?: string;
  formFieldEditable?: boolean;
  formFieldSortable?: boolean;
  formFieldFilterable?: boolean;
  formFieldFixed?: boolean;
  formFieldShape?: string;
  formFieldBucketId?: string;
  formBucket?: boolean;
  formFieldCalculation?: string;
  numberField1: number;
  numberField2: number;
  numberField3: number;
  numberField4: number;
  numberField5: number;
  numberField6: number;
  numberField7: number;
  numberField8: number;
  numberField9: number;
  numberField10: number;
  textField1: string;
  textField2: string;
  textField3: string;
  textField4: string;
  textField5: string;
  textField6: string;
  textField7: string;
  textField8: string;
  textField9: string;
  textField10: number;
  textField11: string;
  textField12: string;
  textField13: string;
  textField14: string;
  textField15: string;
  textField16: string;
  textField17: string;
  textField18: string;
  textField19: string;
  textField20: string;
  textField21: string;
  textField22: string;
  textField23: string;
  textField24: string;
  textField25: string;
  textField26: string;
  textField27: string;
  textField28: string;
  textField29: string;
  textField30: string;
  textField31: string;
  textField32: string;
  textField33: string;
  textField34: string;
  textField35: string;
  textField36: string;
  textField37: string;
  textField38: string;
  textField39: string;
  textField40: string;
  formFieldValidation: string;
  formFieldValidationMessage: string;
  bestCase: number;
  private: number;
  sync?: any;
}

const nodeGroupingRecord = Record({
  id: undefined,
  level: 0,
  positionX: 0,
  relationships: { parents: [], children: [] },
  nodeDataId: undefined
});

export class NodeGrouping extends nodeGroupingRecord {
  id: number;
  level: number;
  positionX: number;
  relationships: { parents: string[]; children: string[] };
  nodeDataId: string;
}

const nodeSidestepRecord = Record({
  id: undefined,
  level: 0,
  positionX: 0,
  originalId: 0,
  nodeDataId: undefined
});

export class NodeSidestep extends nodeSidestepRecord {
  id: string;
  level: number;
  positionX: number;
  originalId: string;
  nodeDataId: string;
}
